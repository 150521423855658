var Turbolinks = require("turbolinks")
import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"


import './main.scss'

import './components/page/page.js'
import './components/page/contact.js'
import './components/product/product.js'

Turbolinks.start()

const application = Application.start()
const context = require.context("./controllers", true, /\.js$/)
application.load(definitionsFromContext(context))
