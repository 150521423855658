import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["inview", "photo"]

  initialize() {
    console.log(`init product-photo controller`)
  }
  connect() {
    console.log(`connect to product-photo controller`)
    this.displayPhotoInView()

    //https://www.kirupa.com/html5/detecting_touch_swipe_gestures.htm
    this.inviewTarget.addEventListener('touchstart', event => {
      this.startTouch(event)
    })
    this.inviewTarget.addEventListener('touchmove', event => {
      this.moveTouch(event)
    })
  }

  next() {
    this.setPhoto(this.index + 1)
  } 
  previous() {
    this.setPhoto(this.index - 1)
  }

  displayPhotoInView() {
    this.inviewTarget.querySelector('img').src = this.currentPhoto.src
  }

  setPhoto(index) {
    if(index >= this.maxPhoto || index < 0) { return }
    this.data.set('index', index)
    this.displayPhotoInView()
  }

  startTouch(e){
    //https://css-tricks.com/the-javascript-behind-touch-friendly-sliders/
    setTimeout(function() {
      // Since the root of setTimout is window we can’t reference this. That’s why this variable says window.slider in front of it.
      console.log(`test for flick?`)
    }, 250);

    initialTouchX = e.touches[0].clientX;
    initialTouchY = e.touches[0].clientY;
  }
  moveTouch(e) {
    e.preventDefault();
    if (initialTouchX === null) { return; }
    if (initialTouchY === null) { return; }
  
    var currentX = e.touches[0].clientX;
    var currentY = e.touches[0].clientY;
  
    var diffX = initialTouchX - currentX;
    var diffY = initialTouchY - currentY;
  
    if (Math.abs(diffX) > Math.abs(diffY)) {
      // sliding horizontally
      if (diffX > 0) {
        this.previous()
      } else {
        this.next()
      }  
    } 
  
    initialTouchX = null;
    initialTouchY = null;
  }

  get index() {
    return parseInt(this.data.get('index'))
  }

  get currentPhoto() {
    return this.photoTargets[this.data.get('index')]
  }

  get maxPhoto() {
    return this.photoTargets.length
  }
}

var initialTouchX = null;
var initialTouchY = null;

