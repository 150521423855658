document.addEventListener('turbolinks:load', function(){
  const btnNavmenu = document.querySelector('.btn-navmenu')
  const navHeader = document.querySelector('header nav')

  btnNavmenu.addEventListener('click', function(){
    btnNavmenu.classList.toggle('active')
    navHeader.classList.toggle('hide')
  })

  // reset toggle state when window resize
  window.addEventListener('resize', e => { 
    btnNavmenu.classList.remove('active') 
    navHeader.classList.add('hide')
  })
})
