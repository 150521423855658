//import './product.scss'

document.addEventListener('turbolinks:load', function(){
  const productPage = document.getElementById('product')
  const photos = Array.from(document.querySelectorAll('.product--photo'))

  if(productPage != null && photos.length > 0){

    let inView = document.querySelector('.product--photo--inview')
    //inView.innerHTML = photos[0].outerHTML

    /*
    photos.forEach(photo => {
      photo.addEventListener('click', function(e){
        e.preventDefault()

        let photoInView = document.querySelector('.product--photo--inview figure')
        photoInView.firstElementChild.remove()

        let clickedImg = e.target
        let newView = clickedImg.cloneNode()
        photoInView.insertAdjacentElement('afterbegin', newView)
      })
    })
*/
  }
})
